<!--

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

-->

<template>
  <CRow>
    <CCol>
      <CForm>
        <CCard>
          <CCardHeader>
            <strong>{{ $t("network.connect") }}</strong>
          </CCardHeader>
          <CCardBody>
            <div role="group" class="form-group form-row">
              <label class="col-form-label col-sm-3">{{
                $t("network.config")
              }}</label>
              <div class="col-sm-9">
                <b-form-file
                  v-model="network.file"
                  :placeholder="$t('network.configChoose')"
                  lang="ru"
                  class="mb-3"
                  required
                  :browse-text="$t('common.browse')"
                ></b-form-file>
              </div>
            </div>
          </CCardBody>
          <CCardFooter>
            <CButton
              type="submit"
              @click.prevent="readFile"
              color="success"
              class="d-flex align-items-center"
              :disabled="!canSubmitForm"
            >
              <CIcon class="mr-1" name="cil-check-circle" />{{
                $t("network.connectNetwork")
              }}
            </CButton>
          </CCardFooter>
        </CCard>
      </CForm>
    </CCol>
  </CRow>
</template>

<script>
import { BFormFile } from "bootstrap-vue";
import utilMessages from "../../../utils/messages";

export default {
  name: "NetworkConnect",
  components: {
    BFormFile,
  },
  data() {
    return {
      network: {
        file: null,
      },
    };
  },
  methods: {
    fileChange($event) {
      this.network.file = $event.target.files[0];
    },
    readFile() {
      const { file } = this.network;
      let reader = new FileReader();
      reader.readAsText(file);

      reader.onload = () => {
        console.log("result", reader.result);
        const json = reader.result;
        this.importNetwork(json);
      };

      reader.onerror = function () {
        this.$toast.error(
          utilMessages.errMessage(
            this.$i18n.t("network.readError"),
            reader.error
          )
        );
      };
    },
    async importNetwork(json) {
      try {
        const data = JSON.parse(json);
        const network = await this.$store.dispatch("importNetwork", data);

        await this.$store.dispatch("switchBlockchain", network.id);
        this.$router.push({ path: `/networks/${network.id}` });
        this.$toast.success(this.$i18n.t("network.connected"));
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },
  },
  computed: {
    canSubmitForm() {
      return this.network.file !== null;
    },
  },
};
</script>
